import React from "react"
import { Carousel } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import GREEN_ARROW from "../../images/arrow-green.png"

const HomeSlider = () => {
  const data = useStaticQuery(graphql`
    {
      allMainImage {
        nodes {
          title
          subTitle
          imagePaths
          url
          hugeImg: featuredImg {
            childImageSharp {
              fixed(width: 3000, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          desktopImg: featuredImg {
            childImageSharp {
              fixed(width: 1000, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          mobileImg: featuredImg {
            childImageSharp {
              fixed(width: 600, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="hero">
      <Carousel>
        {data.allMainImage.nodes.map((slide) => {
          let images = [
            slide.desktopImg.childImageSharp.fixed,
            {
              ...slide.hugeImg.childImageSharp.fixed,
              media: `min-width: 1000`,
            },
            {
              ...slide.mobileImg.childImageSharp.fixed,
              media: `min-width: 768`,
            },
          ]
          return (
            <Carousel.Item key={slide.title}>
              <Img
                fixed={images}
                style={{ width: "100%", margin: 0 }}
                objectFit="cover"
                objectPosition="50% 50%"
              />
              <Carousel.Caption className="boxed">
                <h2>{slide.title}</h2>
                <p>
                  {slide.subTitle}
                  <br />
                  {slide.url && (
                    <Link
                      className="link"
                      to={slide.url}
                      style={{
                        background: `url(${GREEN_ARROW}) no-repeat center right`,
                      }}
                    ></Link>
                  )}
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          )
        })}
      </Carousel>
    </div>
  )
}
export default HomeSlider
