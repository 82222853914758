import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { Container, Row, Col } from "react-bootstrap"

const HomeGrid = () => {
  const data = useStaticQuery(graphql`
    {
      allFeaturedProduct(
        filter: { product: { name: { ne: null } }, enabled: { eq: true } }
        limit: 6
        sort: { order: ASC, fields: order }
      ) {
        nodes {
          product {
            name
            id
            slug
            imagePaths
            storeUrl
            featuredImg {
              childImageSharp {
                fixed(width: 300) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          enabled
        }
      }
    }
  `)
  return (
    <Container className="products">
      <h2>Most Popular Products</h2>
      <Row>
        {data.allFeaturedProduct.nodes.map((productData) => (
          <Col key={productData.product.id} md={4} className="item">
            <a
              className="_link"
              href={`${process.env.B2B_COMMERCE_BASEURL}/${productData.product.storeUrl}`}
            />
            <Img
              style={{ height: "300px", width: "100%", margin: 0 }}
              objectFit="cover"
              objectPosition="50% 50%"
              fixed={productData.product.featuredImg.childImageSharp.fixed}
            />

            <h3>{productData.product.name}</h3>
          </Col>
        ))}
      </Row>
    </Container>
  )
}
export default HomeGrid
