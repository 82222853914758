import React, { useEffect } from "react"
import { JSONLD, Generic } from "react-structured-data"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import Main from "../components/main/main"

const IndexPage = () => {
  useEffect(() => {
    if (window.location.href.includes("blog.walpoleoutdoors.com"))
      window.location.assign("https://walpoleoutdoors.com/blog")
  }, [])

  return (
    <Layout className="main inner-page-with-banner">
      <SEO
        title={`Custom Pergolas, Arbors, Gates, AZEK Fence | Walpole Outdoors`}
        description={`Since 1933 Walpole Outdoors has been transforming your home & garden. We offer the highest quality products, from custom fences and pergolas etc.`}
      />
      <JSONLD>
        <Generic
          type="review"
          jsonldtype="LocalBusiness"
          schema={{
            "@id": "https://walpoleoutdoors.com",
            name: "Walpole Outdoors",
            url: "https://walpoleoutdoors.com",
            telephone: "+18663436948",
            image: "https://www.walpoleoutdoors.com/images/aboutus/top.jpg",
            sameAs: [
              "https://www.facebook.com/WalpoleOutdoors",
              "https://www.pinterest.com/walpoleoutdoors",
              "https://www.houzz.com/professionals/decks-patios-and-outdoor-enclosures/walpole-outdoors-pfvwus-pf~1384246998?",
            ],
          }}
        >
          <Generic
            type="address"
            jsonldtype="PostalAddress"
            schema={{
              streetAddress: "255 Patriot Place",
              addressLocality: "Foxborough",
              addressRegion: "MA",
              postalCode: "02035",
              addressCountry: "US",
            }}
          />
        </Generic>
      </JSONLD>

      <Main />
    </Layout>
  )
}

export default IndexPage
