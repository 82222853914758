import React from "react"
import { Link } from "gatsby"
import HomeSlider from "../layout/homeslider"
import HomeCarousel from "../layout/homecarousel"
import HomeGrid from "../layout/homegrid"
import BlogGrid from "../layout/bloggrid"
import InnerWrapper from "../layout/innerWrapper"
import { Container, Row } from "react-bootstrap"

const Main = () => {
  return (
    <>
      <HomeSlider />
      <InnerWrapper>
        <h1 class="generalheader centered">Custom Outdoor Spaces</h1>
        <HomeCarousel />
        <HomeGrid />
        <BlogGrid />
      </InnerWrapper>
    </>
  )
}

export default Main
