import React from "react"
import Slider from "react-slick"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import pergolaImage from "../../images/portfolio/pergola.jpg"
import fencingImage from "../../images/portfolio/fencing.jpg"
import outdoorShowerImage from "../../images/portfolio/outdoor-showers.jpg"
import mailImage from "../../images/portfolio/mail-lanterns.jpg"

const HomeCarousel = () => {
  const files = useStaticQuery(graphql`
    {
      pergolaFile: file(relativePath: { eq: "portfolio/pergola.jpg" }) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      fencingFile: file(relativePath: { eq: "portfolio/fencing.jpg" }) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      outdoorShowersFile: file(
        relativePath: { eq: "portfolio/outdoor-showers.jpg" }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mailFile: file(relativePath: { eq: "portfolio/mail-lanterns.jpg" }) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const settings = {
    autoplay: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 8000,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const data = [
    {
      name: "FENCES & GATES",
      image: fencingImage,
      slug: "fences-and-gates",
      fixedImage: files.fencingFile.childImageSharp.fixed,
    },
    {
      name: "PERGOLAS & ARBORS",
      image: pergolaImage,
      slug: "pergolas-and-arbors",
      fixedImage: files.pergolaFile.childImageSharp.fixed,
    },
    {
      name: "MAIL & LANTERN POSTS",
      image: mailImage,
      slug: "mail-and-lantern-posts",
      fixedImage: files.mailFile.childImageSharp.fixed,
    },
    {
      name: "OUTDOOR SHOWERS",
      image: outdoorShowerImage,
      slug: "outdoor-showers",
      fixedImage: files.outdoorShowersFile.childImageSharp.fixed,
    },
  ]

  return (
    <Slider className="slick homecarousel" {...settings}>
      {data.map((slideData) => (
        <div className="item" key={slideData.name}>
          <Link
            className="_link"
            to={`/custom-projects/${slideData.slug}`}
          ></Link>
          <Img
            style={{ height: "300px", width: "100%" }}
            objectFit="cover"
            objectPosition="50% 50%"
            fixed={slideData.fixedImage}
          />

          <h2>{slideData.name}</h2>
        </div>
      ))}
    </Slider>
  )
}

export default HomeCarousel
