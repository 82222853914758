import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import HTMLEllipsis from "react-lines-ellipsis/lib/html"

const BlogGrid = () => {
  const data = useStaticQuery(graphql`
    {
      allBlog(sort: { fields: postedDate, order: DESC }, limit: 3) {
        edges {
          node {
            postedDate
            title
            id
            content
            imagePaths
            seoURL
          }
        }
      }
    }
  `)
  return (
    <div className="blog">
      <Container>
        <h2>Outdoor Living</h2>
        <Row>
          {data.allBlog.edges.map(({ node }) => (
            <Col md={4} sm={12} className="item blogitem" key={node.id}>
              <div className="blogitemcontainer">
                <figure
                  style={{ backgroundImage: `url(${node.imagePaths[0]})` }}
                >
                  <img />
                </figure>
                <div className="text">
                  <h3>{node.title}</h3>
                  <div className="meta">
                    Posted on {node.postedDate} by Walpole Outdoors{" "}
                  </div>
                  <div className="entry-content blogsummary home-page-blog">
                    <HTMLEllipsis
                      unsafeHTML={node.content}
                      maxLine="3"
                      ellipsis="..."
                      basedOn="letters"
                    />
                    {/* <div
                      dangerouslySetInnerHTML={{
                        __html: node.content,
                      }}
                    /> */}
                  </div>

                  <Link to={`/blogs/${node.seoURL}`}>Read More</Link>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}
export default BlogGrid
